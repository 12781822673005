.box {
    align-self: flex-end;
    animation-duration: 0.55s;
    animation-iteration-count: infinite;
    margin: 0 auto 0 auto;
    transform-origin: bottom;
    flex-direction: column;
      align-items: center;
      
  }
  .bounce {
    animation-name: bounce-1;
    animation-timing-function: linear;
  }
  @keyframes bounce-1 {
    0%   { transform: translateY(0); }
    50%  { transform: translateY(-30px); }
    100% { transform: translateY(0); }
  }
  .backgroundScreen {
    background-color: #303c54;
  }