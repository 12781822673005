// If you want to override variables do it here
@import 'variables';

// Import CoreUI styles
@import '~@coreui/coreui/scss/coreui.scss';

// // Import bootstrap
// @import "~bootstrap/scss/bootstrap";
// @import "bootstrap/dist/css/bootstrap.min.css";

// Some temp fixes
@import 'fixes';

// If you want to add something do it here
@import 'custom';
@import "~@coreui/coreui/scss/variables/components/tables";
// @import '@coreui/coreui/dist/css/coreui.min.css';

input[type='text']:focus,
input[type='password']:focus,
input[type='datetime']:focus,
input[type='datetime-local']:focus,
input[type='date']:focus,
input[type='month']:focus,
input[type='time']:focus,
input[type='week']:focus,
input[type='number']:focus,
input[type='email']:focus,
input[type='url']:focus,
input[type='search']:focus,
input[type='tel']:focus,
input[type='color']:focus,
textarea[type='text']:focus,
.uneditable-input:focus {
  border-color: #78c7ee;
  outline: 0;
  box-shadow: inherit;
}

select:focus {
  outline: 0 !important;
  box-shadow: inherit !important;
  border-color: #78c7ee !important;
}

button:focus {
  outline: 0 !important;
  box-shadow: inherit !important;
  border-color: #78c7ee !important;
}

.react-confirm-alert-overlay {
  z-index: 1051 !important;
}

body.react-confirm-alert-body-element {
  overflow: inherit !important;
}

// edit button color
.requiredIcon:after {
  color: #d00;
  content: '*';
  position: absolute;
  margin-left: 5px;
}

.switchButton .c-switch-input:checked + .c-switch-slider {
  background-color: #0093dd;
  border-color: #0093dd;
}

.custom-control-input:checked ~ .custom-control-label::before {
  border-color: #0093dd;
  background-color: #0093dd;
}

.dropdown-item.active,
.dropdown-item:active {
  text-decoration: none;
  background-color: #0093dd;
}

.page-item.active .page-link {
  background-color: #0093dd;
  border-color: #0093dd;
}

.page-link {
  color: #0093dd;
}

.blue-button {
  border: 1px solid !important;
  border-color: #0093dd !important;
  background-color: #0093dd !important;
}

.fade-blue-button {
  border-color: #89c4f4;
  background-color: #89c4f4;
}

.react-datepicker__close-icon::after {
  background-color: #0093dd !important;
  padding: 0px !important;
  vertical-align: initial !important;
}

.constBackground {
  background-color: #0093dd;
}

.customButtonSize {
  padding: 0.185rem 0.75rem;
}

select {
  text-align: center;
  text-align-last: center;
}

option {
  text-align: left;
}

.modal-content {
  border-radius: 0 !important;
}

#alterationDevice .modal-dialog {
  min-width: 90%;
  margin: 1.75rem auto;
}

@media (min-width: 100px) {
  #alterationDevice .modal-dialog {
    margin: 1.75rem auto;
    min-width: 48vw;
  }
}

@media (min-width: 100px) {
  #alterationDeviceFile .modal-dialog {
    margin: 1.75rem auto;
    min-width: 65vw;
  }
}

@media (max-height: 100px) {
  #tablesStation.modal-dialog {
    margin: 1.75rem auto;
    min-width: 80vw;
  }
}

.tippy-content {
  overflow: hidden;
  width: auto !important;
  height: auto !important;
  max-width: 40vw !important;
  max-height: 70vh !important;
  background-color: #333 !important;
}

.css-12jo7m5 {
  max-width: 200px !important;
}

.table-responsive {
  overflow-y: auto;
  max-height: 70vh;
  display: block;
}

.table-responsive thead tr {
  position: sticky;
  top: 0;
  // z-index: 2;
}

.fix-fa .fa {
  display: contents !important;
}

.table-responsive thead tr th {
  background-color: white;
  position: relative !important;
}

.table-responsive thead tr:after {
  position: absolute;
  top: 0 !important;
  left: 0;
  width: 100%;
  height: 0%;
  background-color: #d8dbe0;
  z-index: 999999;
}

.table-responsive::-webkit-scrollbar {
  width: 0;
  background: transparent;
}

.all-scroll {
  cursor: all-scroll;
}

.mouse-icon {
  cursor: zoom-in;
}

.paging ul {
  margin: 0 !important;
}

.dropdown-toggle::after {
  margin: 0.6em 5px;
  float: right;
}

button .is-invalid {
  border-color: #e55353;
}

.modal-header {
  border-bottom: none !important;
}

table .table-hover tbody tr {
  cursor: pointer;
}

.disable .dropdown {
  background-color: #d8dbe0;
  opacity: 1;
}

@media (max-width: 700px) {
  .my-flex > div {
    width: 100%;
    margin: auto;
  }
}

@media (max-width: 400px) {
  .my-flex > div {
    display: none;
  }
}

.ck-editor__editable {
  min-height: 150px;
}

table.table-120 {
  width: 120%;
}

a.how-to-use {
  text-transform: uppercase;
  color: #39f;
  font-weight: 500;
}
.table-responsive thead tr {
  position: relative !important;
}
thead {
  position: sticky;
  // z-index: 1000;
  top: 0;
}
table.table-striped table-hover table-bordered {
  border-spacing: 0;
  border-collapse: initial;
}
table {
  border-spacing: 0;
  border-collapse: initial;
}